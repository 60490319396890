
import Vue, { PropType } from 'vue'
import { IGCB2Connector, ManualCommunication, SideBarSection, SidebarItem, SidebarType } from '@t/GCB2'
import * as t from '@root/src/store/chisai/GCB2/actionTypes'
import ClientCard from '@/components/chisai/GCB2/clientInfo/ClientCard.vue'
import ActivationCard from '@/components/chisai/GCB2/clientInfo/ActivationCard.vue'
import NeedToContactCard from '@/components/chisai/GCB2/clientInfo/NeedToContactCard.vue'
import VisitHistoryCard from '@/components/chisai/GCB2/clientInfo/VisitHistoryCard.vue'
import CommentHistoryCard from '../../../components/chisai/GCB2/clientInfo/CommentHistoryCard.vue'

import ActionButtons from '../../../components/chisai/GCB2/clientInfo/ActionButtons.vue'
import { useGcb2Store } from '@root/src/store/stores/gcb2/gcb2'
import { mapStores } from 'pinia'
import { Connector, Project } from '../../../types/main'
import { CONNECTOR_TYPES } from '../../../vars/general'
import { TASKLIST_STATUSES } from '../../../vars/GCB2'
import { telephonyService } from '../../../services/telephony'
import { connect } from 'socket.io-client'
import { getClientVisitsHistoryData } from '../../../api/chisai/GCB2'
export default Vue.extend({
    components: {
        ClientCard,
        ActivationCard,
        NeedToContactCard,
        VisitHistoryCard,
        ActionButtons,
        CommentHistoryCard,
    },
    props: {},
    data: () => ({
        visitHistoryLoading: false,
        expanded: [] as any[],
        TASKLIST_STATUSES,
        updateFeedTimestamp: 0 as Number,
        updateFeedButtonLoading: false as Boolean,
        visitHistoryData: [] as any[],
    }),
    watch: {
        activeSidebarItem: {
            immediate: true,
            async handler(newVal, oldVal) {
                if (!this.activeSidebarItem) return
                this.visitHistoryLoading = true
                const response = await getClientVisitsHistoryData({
                    projectId: this.project.id,
                    clientId: this.activeSidebarItem.clientId,
                })
                if (response.error) {
                    this.$store.dispatch('callNotify', 'При загрузке истории посещений произошла ошибка ', {
                        root: true,
                    })
                    this.visitHistoryLoading = false
                    return
                }
                this.visitHistoryData = response.data!
                this.gcb2Store.setVisitHistoryNeedUpdate(
                    this.project.id,
                    this.activeSidebarItem.clientId,
                    this.visitHistoryData
                )
                this.visitHistoryLoading = false
            },
        },
    },
    computed: {
        ...mapStores(useGcb2Store),
        activeManualCommunicationId(): string | null {
            return this.gcb2Store.sidebar.communicationId
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },

        gcb2Connector(): IGCB2Connector {
            return this.project.connectors.find((el: Connector) => el.connectorType === CONNECTOR_TYPES.GCB2)!
        },
        megaphoneConnector(): Connector | undefined {
            return this.project.connectors.find(
                (el: Connector) => el.connectorType === CONNECTOR_TYPES.telephonyMegaphone
            )
        },
        showFeedUpdateButton(): Boolean {
            return Boolean(
                this.project.connectors.some(connector =>
                    [
                        CONNECTOR_TYPES.telephonyBeeline,
                        CONNECTOR_TYPES.telephonyMango,
                        CONNECTOR_TYPES.telephonyMegaphone,
                    ].includes(connector.connectorType)
                )
            )
        },
        activeSidebarItem(): SidebarItem | null {
            return this.gcb2Store.sidebar.item
        },
        sidebarType(): SidebarType | null {
            return this.gcb2Store.sidebar.type
        },
        activeManualCommunication(): ManualCommunication {
            return (
                this.$store.getters[`GCB2/${t.GET_ONE_MANUAL_COMMUNICATION}`](
                    this.activeManualCommunicationId
                ) ?? {
                    activation: {},
                }
            )
        },
        showRecomendations(): boolean {
            return (
                this.sidebarType === 'tasklist' &&
                this.activeManualCommunication.status !== TASKLIST_STATUSES.DONT_REMIND &&
                this.activeManualCommunication.status !== TASKLIST_STATUSES.DONT_REMIND_SERVICE
            )
        },
        needUpdateVisitHistory(): boolean {
            return this.gcb2Store.needUpdateVisitHistory(
                this.project.id,
                this.activeManualCommunication.clientId
            )
        },
    },
    methods: {
        async updateFeed() {
            this.updateFeedButtonLoading = true
            telephonyService
            await telephonyService.updateCallHistory(this.project.connectors)
            this.updateFeedTimestamp = Date.now()
            this.updateFeedButtonLoading = false
        },
    },

    mounted() {},
})
