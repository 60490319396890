
import Vue, { PropType } from 'vue'
import { CommunicationTag, ManualCommunication, SidebarItem } from '@/types/GCB2'
import * as _ from 'lodash'
import SpinnerLoader from '../../../SpinnerLoader.vue'
import { getClientCommentHistory } from '../../../../api/chisai/GCB2'
import moment from 'moment'
import { mapStores } from 'pinia'
import { useGcb2Store } from '../../../../store/stores/gcb2/gcb2'
import { Project } from '../../../../types/main'

export default Vue.extend({
    components: { SpinnerLoader },
    props: {
        activeSidebarItem: { type: Object as PropType<SidebarItem> },
        updateFeedTimestamp: { type: Number },
    },
    data: () => ({
        DATE_FORMAT: 'DD.MM.YYYY',
        FULL_DATE_FORMAT: 'DD.MM.YYYY HH:mm',
        loading: true as boolean,
        feed: [] as any,
        moment,
    }),
    computed: {
        ...mapStores(useGcb2Store),
        communicactionTags(): CommunicationTag[] {
            return this.gcb2Store.getAllCommunicationTags(this.project.id)
        },
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
    },
    watch: {
        activeSidebarItem: {
            immediate: true,
            async handler() {
                await this.updateFeed()
            },
        },
        updateFeedTimestamp: {
            async handler() {
                await this.updateFeed()
            },
        },
    },
    methods: {
        isNil: _.isNil,
        getCallDuration(item: any) {
            let duration = ''
            if (!_.isNil(item.durationMin)) {
                duration += `${item.durationMin} мин., `
            }
            if (!_.isNil(item.durationSec)) {
                duration += `${item.durationSec} сек.`
            }
            return duration
        },
        getTagName(tagId: string) {
            return this.communicactionTags.find(el => el.id === tagId)?.name ?? ''
        },
        getCommentTitle(item: any) {
            if (item.commentOnEntityType === 'ban') return 'Комментарий к «Не напоминаем»'
            if (item.commentOnEntityType === 'postponed_communication') return 'Комментарий к откладыванию'
            return 'Комментарий'
        },
        async updateFeed() {
            this.loading = true
            const response = await getClientCommentHistory({
                projectId: this.project.id,
                clientId: this.activeSidebarItem.clientId,
            })
            if (response.error) {
                this.$store.dispatch('callNotify', 'При загрузке данных произошла ошибка ', {
                    root: true,
                })
            }

            this.feed = response.data
            this.loading = false
        },
        showCallData(item: any) {
            return (!_.isNil(item.durationMin) || !_.isNil(item.durationSec)) && item.actionType === 'call'
        },
    },
    async created() {},
    mounted() {},
})
