import api from '../api/api'
import { Connector } from '../types/main'
import { CONNECTOR_TYPES, TELEPHONY_CONNECTOR_TYPES } from '../vars/general'

export const telephonyService = { getTelephonyConnector, updateCallHistory }

function getTelephonyConnector(connectors: Connector[]) {
    return connectors.find(connector => TELEPHONY_CONNECTOR_TYPES.includes(connector.connectorType))
}
async function updateCallHistory(connectors: Connector[]) {
    const { connectorType, connectorId } = getTelephonyConnector(connectors)!
    if (connectorType === CONNECTOR_TYPES.telephonyMegaphone) {
        const res = await api.connectors.telephonyMegaphone.updateCallHistory({ connectorId: connectorId })
        return res
    }
    if (connectorType === CONNECTOR_TYPES.telephonyBeeline) {
        const res = await api.connectors.telephonyBeeline.updateCallHistory({ connectorId: connectorId })
        return res
    }
    if (connectorType === CONNECTOR_TYPES.telephonyMango) {
        const res = await api.connectors.telephonyMango.updateCallHistory({ connectorId: connectorId })
        return res
    }
}
