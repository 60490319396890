
import Vue, { PropType } from 'vue'
import { ManualCommunication, SidebarItem } from '@/types/GCB2'
import * as t from '@root/src/store/chisai/GCB2/actionTypes'
import { VISIT_STATUSES } from '../../../../vars/GCB2'
import SpinnerLoader from '../../../SpinnerLoader.vue'
import { getClientVisitsHistoryData } from '../../../../api/chisai/GCB2'
import { Project } from '../../../../types/main'
import moment from 'moment'
import { useGcb2Store } from '../../../../store/stores/gcb2/gcb2'
import { mapStores } from 'pinia'
export default Vue.extend({
    components: { SpinnerLoader },
    props: {
        visitHistoryData: { type: Array as PropType<any[]> },
        loading: { type: Boolean },
    },
    data: () => ({
        DATE_FORMAT_VISIT: 'DD.MM.YYYY HH:mm',
        loading: true as boolean,
        visitHistoryData: [] as any,
        moment,
    }),
    computed: {
        ...mapStores(useGcb2Store),
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
    },

    methods: {
        getIconByStatus(status: string) {
            if (status === VISIT_STATUSES.ATTENDANCE) {
                return 'mdi-plus-circle'
            } else if (status === VISIT_STATUSES.RECORD) {
                return 'mdi-clock-outline'
            } else if (status === VISIT_STATUSES.CANCEL) {
                return 'mdi-minus-circle'
            }
        },
    },
    async created() {},
    mounted() {},
})
