
import Vue, { PropType } from 'vue'
import { ManualCommunication } from '@/types/GCB2'
import * as t from '@root/src/store/chisai/GCB2/actionTypes'
import SpinnerLoader from '../../../SpinnerLoader.vue'
import { declOfNum } from '../../../../utils'
import moment from 'moment'

const Communication = 'communication'
const Abonements = 'abonements'

export default Vue.extend({
    components: { SpinnerLoader },
    props: { manualCommunication: { type: Object as PropType<ManualCommunication> } },
    data: () => ({
        DATE_FORMAT_ATTENDANCE: 'DD.MM.YYYY',
        DATE_FORMAT_FORECAST: 'DD.MM.YYYY',
        loading: true as boolean,
        needToContactData: {} as any,
        communicationTypes: {
            Communication,
            Abonements,
        },
    }),
    computed: {
        showWidget(): any {
            return this.needToContactData && this.needToContactData.showWidget
        },
        isGCB2Sidebar(): boolean {
            return this.$store.state.GCB2.isGCB2Sidebar
        },
        recomendationsData(): any[] | undefined {
            return this.needToContactData.data.recomendationsDefault
        },
        abonementRecomendations(): any[] | undefined {
            return this.needToContactData.data?.recomendations && this.needToContactData.data?.recomendations[0]?.abonements ? this.needToContactData.data?.recomendations[0]?.abonements : null
        },
        communicationsRecomendations(): any[] | undefined {
            return this.needToContactData.data?.communications
        },
        serviceCallRecomendationsData(): any[] | undefined {
            return this.needToContactData.data.recomendationsServiceCall
        },
        plainTextRecomendations(): any {
            return this.needToContactData.data?.plainTextRecomendations
        },
        botMarksRecomendations(): any {
            return this.needToContactData.data?.botMarksRecomendations
        },
        lostRecomendations(): any[] | undefined {
            return this.needToContactData.data?.recomendations && !this.needToContactData.data?.recomendations[0]?.abonements ? this.needToContactData.data?.recomendations : null
        },
        birthdayData(): { day: string; year: number; month: string } {
            return this.needToContactData.data.clientBirthday
        },
        birthdayString(): string {
            const birthdayYear = this.birthdayData.year
                ? this.birthdayData.year < 1920
                    ? null
                    : this.birthdayData.year
                : null
            let date = new Date(
                birthdayYear ?? 0,
                parseInt(this.birthdayData.month) - 1,
                parseInt(this.birthdayData.day)
            )

            let formatter = new Intl.DateTimeFormat('ru', {
                year: birthdayYear ? 'numeric' : undefined,
                month: 'long',
                day: 'numeric',
            })
            const currentClientAge = birthdayYear ? moment().diff(date, 'years') : null
            return `Дата рождения: \n ${formatter.format(date).replace('г.', 'года')}  ${
                currentClientAge
                    ? `(сейчас — ${currentClientAge} ${declOfNum(currentClientAge, ['год', 'года', 'лет'])})`
                    : ''
            }`
        },
    },

    watch: {
        manualCommunication: {
            immediate: true,
            async handler(newVal, oldVal) {
                if (!this.manualCommunication.communicationId) return
                this.loading = true
                await this.$store.dispatch(`GCB2/${t.FETCH_ADD_NEED_TO_CONTACT_WIDGET_DATA}`, {
                    projectId: this.manualCommunication.projectId,
                    communicationId: this.manualCommunication.communicationId,
                    activationId: this.manualCommunication.activationId,
                    clientId: this.manualCommunication.clientId,
                })
                this.needToContactData = this.$store.getters[`GCB2/${t.GET_ONE_NEED_TO_CONTACT_WIDGET_DATA}`](
                    this.manualCommunication.communicationId
                )

                this.loading = false
            },
        },
    },

    methods: {
        getLastVisitText(item: any) {
            if (!item.visitDate) return ''
            return `Предыдущий визит: ${moment(item.visitDate).format(this.DATE_FORMAT_ATTENDANCE)}${
                item.staffName ? `, ${item.staffName}` : ''
            }`
        },
        getExceptedVisitText(item: any) {
            if (!item.nextVisitFcDate) return ''
            const exceptedText = moment(item.nextVisitFcDate).isSameOrAfter(moment().format('YYYY-MM-DD'))
                ? 'Ожидается'
                : 'Ожидалось'
            return `${exceptedText} возвращение: ${moment(item.nextVisitFcDate).format(
                this.DATE_FORMAT_FORECAST
            )}`
        },
    },
    async created() {},
    mounted() {},
})
