
import Vue, { PropType } from 'vue'
import { IGCB2Connector, ManualCommunication, SidebarItem } from '@/types/GCB2'
import YClientsLink from '@/components/iconLinks/YClientsLink.vue'
import DikidiLink from '@/components/iconLinks/DikidiLink.vue'
import ProfSalonLink from '@/components/iconLinks/ProfSalonLink.vue'
import PhoneLink from '@/components/iconLinks/PhoneLink.vue'
import SonlineLink from '@/components/iconLinks/SonlineLink.vue'
import AltegioLink from '@/components/iconLinks/AltegioLink.vue'
import { Connector } from '../../../../types/main'
import { CONNECTOR_TYPES } from '../../../../vars/general'
import messageVarsService from '../../../../helpers/gcb2/messageVars'
import AmoCrmLink from '../../../iconLinks/AmoCrmLink.vue'
import { checkRole } from '../../../../helpers/roles'
import ChatLink from '@root/src/components/iconLinks/ChatLink.vue'
export default Vue.extend({
    components: {
        YClientsLink,
        DikidiLink,
        SonlineLink,
        ProfSalonLink,
        PhoneLink,
        AltegioLink,
        AmoCrmLink,
        ChatLink
    },
    props: { activeSidebarItem: { type: Object as PropType<SidebarItem> }, manualCommunication: { type: Object as PropType<ManualCommunication> } , },
    data: () => ({}),
    computed: {
        projectId(): string {
            return this.$router.currentRoute.params.id
        },
        gcb2Connector(): IGCB2Connector {
            return this.$store.getters
                .projectById(this.projectId)
                .connectors.find((el: Connector) => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        chatLinkSettings(): any {
            return this.gcb2Connector?.whatsappLinkSettings
        },
        /* whatsappTextMessage(): string {
            return messageVarsService.resolveVars(
                this.activeSidebarItem.textMessage,
                this.gcb2Connector,
                this.manualCommunication
            )
        }, */
    },

    methods: { checkRole },
    async created() {},
    mounted() {},
})
