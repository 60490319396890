
import Vue, { PropType } from 'vue'
import { ManualCommunication } from '@/types/GCB2'
import MessageTextEditor from '../../../inputs/MessageTextEditor/MessageTextEditor.vue'
import { CONNECTOR_TYPES } from '../../../../vars/general'
import { Connector, Project } from '../../../../types/main'

export default Vue.extend({
    components: { MessageTextEditor },
    props: { manualCommunication: { type: Object as PropType<ManualCommunication> } },
    data: () => ({}),
    computed: {
        project(): Project {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        GCB2Connector(): Connector | undefined {
            return this.project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2)
        },
    },

    methods: {},
    async created() {},
    mounted() {},
})
